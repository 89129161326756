import React from "react";
import "../../assets/Scss/main.scss";

const Spiner = () => {
  return (
    <div>
      <h2>Loading...</h2>
    </div>
  );
};

export default Spiner;
