import React from "react";
import { Link } from "react-router-dom";
import Spiner from "../../components/Spiner/Spiner";
import { api } from "../../Setting/Setting";
const Home = () => {
  const [categories, setCategories] = React.useState([]);

  React.useEffect(() => {
    fetch(api)
      .then((res) => res.json())
      .then((json) => setCategories(json));
  }, []);
  console.log(categories);

  const removeExtra = (main, extra) => {
    return main.replace(extra, "");
  };

  if (!categories) {
    return <Spiner />;
  }
  return (
    <div className="categories">
      {categories.map((category) => (
        <div
          className="category"
          key={removeExtra(category.brandname, category.count)}
        >
          <Link to={`/brand/${category.slug}`}>
            <h1>{removeExtra(category.brandname, category.count)}</h1>
          </Link>
          <span>{category.count}</span>
        </div>
      ))}
    </div>
  );
};

export default Home;
