import React from "react";
import { Link, useParams } from "react-router-dom";
import Spiner from "../../components/Spiner/Spiner";
import { api } from "../../Setting/Setting";
const Brand = () => {
  let { category } = useParams();

  const [phones, setPhones] = React.useState([]);
  React.useEffect(() => {
    fetch(`${api}/brand/${category}`)
      .then((res) => res.json())
      .then((json) => setPhones(json));
  }, [category]);

  if (!phones) {
    return <Spiner />;
  }

  return (
    <div className="phones">
      {phones.map((phone) => (
        <div className="phone" key={phone.id}>
          <Link to={`/phone/${phone.link}`}>
            <img src={phone.image} alt={phone.name} />
            <h3>{phone.name}</h3>
          </Link>
        </div>
      ))}
    </div>
  );
};

export default Brand;
