import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import "./assets/Scss/main.scss";
import Main from "./components/Layout/Main";
import Brand from "./Pages/Brand/Brand";
import Home from "./Pages/Home/Home";
import Phone from "./Pages/Phone/Phone";
function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Main />}>
          <Route path="/" element={<Home />} />
          <Route path="/brand/:category" element={<Brand />} />
          <Route path="/phone/:model" element={<Phone />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
