import React from "react";
import { Outlet } from "react-router-dom";
import Navbar from "../Navbar/Navbar";
const Main = ({ children }) => {
  return (
    <div>
      <Navbar />
      <div className="wrap">
        <Outlet />
        {children}
      </div>

      <div className="footer">
        <p>
          &copy; 2021 <a href="https://fb.me/clounumber404">Sohel</a>
        </p>
      </div>
    </div>
  );
};

export default Main;
