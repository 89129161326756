import React from "react";
import { useParams } from "react-router-dom";
import Spiner from "../../components/Spiner/Spiner";
import { api } from "../../Setting/Setting";

const Phone = () => {
  const [phone, setPhone] = React.useState([]);
  const { model } = useParams();

  React.useEffect(() => {
    fetch(api + "/phone/" + model)
      .then((res) => res.json())
      .then((json) => setPhone(json));
  }, [model]);

  if (!phone) {
    return <Spiner />;
  }
  const icons = {
    batsize: "fas fa-camera",
    battery: "fas fa-battery-full",
    released: "fas fa-calendar-alt",
    body: "fas fa-mobile-alt",
    os: "fab fa-android",
    storage: "fas fa-hdd",
    displaysize: "fas fa-mobile-alt",
    camerapixels: "fas fa-camera",
    ramsize: "fas fa-memory",
  };

  return (
    <div>
      <div className="head_overview">
        <div className="mobileimg">
          {" "}
          <img src={phone?.photoUrl} alt="a" />
        </div>
        <div className="overview">
          {phone?.overview?.map((item) => (
            <div
              className="overview_item"
              key={item.feature}
              style={{ display: "flex" }}
            >
              <div>
                <i className={icons[item.feature]}></i>
              </div>
              <div>
                {" "}
                <b>{item.value}</b>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="information">
        {phone?.information?.map((item) => (
          <div className="information_item" key={item.tabName}>
            <h3>{item.tabName}</h3>
            {item?.options.map((option) => (
              <div
                className="box"
                key={option.option}
                style={{ display: "flex" }}
              >
                <div>
                  <b>{option.option} </b>
                </div>
                <div> {option.value}</div>
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Phone;
