import React from "react";

const Navbar = () => {
  return (
    <div className="navbar">
      <div className="navbar__logo">
        <h1>Phone</h1>
      </div>
      <div>
        <ul className="navbar__links">
          <li>
            <a href="/">Home</a>
          </li>
          <li>
            <a href="/brand">Brand</a>
          </li>
          <li>
            <a href="/phone">Phone</a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Navbar;
